import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./bagactivation.component.css";
import "../bagdetails/bagdetails.component.css";
// import Backpack from "../../assets/Luggage.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Modal from "@material-ui/core/Modal";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Navbar from "../header/header.component";
import axios from "axios";
import {Snackbar} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as FcIcons from "react-icons/fc";
import CloseIcon from "@material-ui/icons/Close";
import { QrReader } from "react-qr-reader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview.js";
import imageCompression from "browser-image-compression";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
// import Button from '@mui/material/Button';
import { APP_ENV, switchUrls } from "../../api/index.js";
import { CalendarViewMonthSharp } from "@mui/icons-material";
import heic2any from "heic2any";

import Digitalbagcard from "./Digitalbagactivation.jsx";
import Footer from "../mainfooter/footerblack.jsx";
import Sidebarcontent from "../sidebar/Sidebar.jsx";
import MEDICAL_VALUABLE_IMAGE from "../../utils/Helpers.js";
import Inputfield from "../ui/Inputfield.jsx";
import HelpIcon from "@mui/icons-material/Help";
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from '@mui/material/Alert';

const baseURL = switchUrls("bag");
const gateway_frontend = switchUrls("gateway_frontend");
const basegatewayURL = switchUrls("gateway");


function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
const uid = localStorage.getItem("uid");

class LocationErrorModal extends React.Component {
  render() {
    const { open, handleOk } = this.props;

    return (
      <Modal open={open} onClose={handleOk} disableBackdropClick>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px",
            textAlign: "center",
          }}
        >
          <p style={{ marginBottom: "15px" }}>
            We are unable to locate you. Turn on your GPS, please.
          </p>
          <button
            onClick={handleOk}
            style={{
              padding: "10px 15px",
              background: "#fcbc1b",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            OK
          </button>
        </div>
      </Modal>
    );
  }
}

export default class BagActivation extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imageUrl: "",
      selectedImage: null,
      bag_imagelocation: null,
      bag_imagename: null,

      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "Luggage",
      vectorurl: "",
      locationData: {
        latitude: null,
        longitude: null,
      },
      showLocationErrorModal: false,
      modalShown: false,
      retryLocation: false,
      valuable_id: 12,
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/luggage.png",
      valuableTypeToIdMap: {
        "Laptop Bag": 1,
        Briefcase: 2,
        "Camera Bag": 3,
        "Gym Bag": 4,
        "Trolley Bag": 5,
        Suitcase: 6,
        "Ladies Purse": 7,
        "Sports Kit Bag": 8,
        "Duffel Bag": 9,
        "Other Bags": 10,
        "School Bag": 11,
        Luggage: 12,
      },
      valuableImages: {
        1: "laptopbag.png",
        2: "briefcase.png",
        3: "camerabag.png",
        4: "GymBag.png",
        5: "trolley-bag.png",
        6: "suitcase.png",
        7: "ladiespurse.png",
        8: "sport-bag.png",
        9: "duffelbag.png",
        10: "other.png",
        11: "schoolbag.png",
        12: "luggage.png",
      },
      description: "",
      date_of_purchase: "",
      warranty_period: "",
      bag_color: "",
      bag_brand: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
      // isLoading: true,
      showLoader: true,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      imagecroploading: false,
      errorstate: null,
      croperror: false,
      digitalqrdetails: {
        digital_product: false,
        no_of_days: null,
      },
      journey_startdate: "",
      journey_enddate: "",
      medical_tag: false,
      display_name: localStorage?.getItem("name"),
      current_lock: "",
      display_baglock: false,
      warranty_time: ["Days", "Months", "Years"],
      warranty_time_period: "",
      show: false,
      show5: false,
      tagNotFound: false,
      alreadyActivated: false,
      walletDialogOpen: false,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
    this.checkFormedicaltag = this.checkFormedicaltag.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handelwarranty = this.handelwarranty.bind(this);
    this.checkLocation = this.checkLocation.bind(this)
     this.handleShow = this.handleShow.bind(this);
    this.handleAllTags = this.handleAllTags.bind(this);
    this.handleActiveInactive = this.handleActiveInactive.bind(this);
    this.handleLostFound = this.handleLostFound.bind(this);
     this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateTag = this.activateTag.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleQRResult = this.handleQRResult.bind(this);
    this.walletDialogHandleClose = this.walletDialogHandleClose.bind(this);
  }
  handelwarranty(e) {
    this.setState({
      [e?.target?.name]: e?.target?.value,
    });
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  uploadImg() {
    this.setState({
      uploaderror: false,
      message: false,
      imagemodal: false,
      imagecroploading: false,
      croperror: false,
    });
  }
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose() {
    this.setState({ show: false });
  }

  handleInputChange(e) {
    const inputValue = e.target.value
      .replace(/\s/g, "")
      .toUpperCase()
      .slice(0, 10);
    this.setState({ tag_number: inputValue });
  }
   
   handleNotFound = () => {
    this.setState({ tagNotFound: false });
  };

  handleActivated = () => {
    this.setState({ alreadyActivated: false });
  };
  activateTag() {
  const { tag_number } = this.state;
  const state = {
    tag_number: tag_number.toUpperCase(),
  };
  this.setState({ showLoader: true });
  const loaderTimer = setTimeout(() => {
    this.setState({ showLoader: false });
  }, 5000);

  axios
    .post(`${basegatewayURL}/api/checkTagNumberWithoutHeader`, state, {
      headers: {
        Authorization: this.props.token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const productcategory = res.data.tag_data.product_category;
      sessionStorage.removeItem("tag_number");

      // Check if product category starts with "Dolphin" or "Smart"
      if (
        productcategory.startsWith("Dolphin") || 
        productcategory.startsWith("Smart")
      ) {
        this.setState({ walletDialogOpen: true });
        return;
      }

      const routes = {
        "QR PET": `/pet-activation/${tag_number}`,
        "QR Key": `/key-activation/${tag_number}`,
        "QR bag": `/bag-activation/${tag_number}`,
        "QR Bag": `/bag-activation/${tag_number}`,
        "E-Bag": `/bag-activation/${tag_number}`,
        "QR Medical": `/bag-activation/${tag_number}`,
        "QR Passport": `/passport-activation/${tag_number}`,
        "E-Passport": `/passport-activation/${tag_number}`,
        "QR Gadget": `/earphone-activation/${tag_number}`,
        "Generic": `/generic-activation/${tag_number}`,
        "QR Wallet": `/wallet-activation/${tag_number}`,
        "QR Human": `/human-activation/${tag_number}`,
        "QR HUMAN": `/human-activation/${tag_number}`,
        "QR Vehicle": `/#/vehicle-activation/${tag_number}`,
      };

      if (routes[productcategory]) {
        window.location.href = routes[productcategory];
      }
    })
    .catch((error) => {
      clearTimeout(loaderTimer);
      this.setState({ showLoader: false });
      const responseData = error.response.data;
      // console.log(responseData, "data");
      if (responseData === "Tag not found by the specified number.") {
        // this.setState({ tagNotFound: true });
        this.setState({
            tagNotFound: true,
            snackbarOpen: true,
            snackbarMessage: "Tag not found!",
            snackbarType: "warning",
          });

      } else if (responseData === "Tag is already activated.") {
        // this.setState({ show: false, alreadyActivated: true });
        this.setState({
            alreadyActivated: true,
            snackbarOpen: true,
            snackbarMessage: "Tag is already activated.",
            snackbarType: "info",
          });

      }
    });
  }

  handleCloseQRModal() {
    this.setState({ show5: false });
  }

  handleQRResult(result, error) {
    if (result) {
      const activateTag = result?.text
        .split("/")
        [result?.text.split("/").length - 1].toUpperCase();
      this.setState({ activateTag, show5: false });
    }
    if (error) {
      console.error(error.message);
    }
  }

  renderQRModal() {
    return (
      <Dialog
        open={this.state.show5}
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseQRModal}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={this.handleQRResult}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  }
  async checkFormedicaltag() {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/checkforMedicaltag/${this.state?.tag_number}`
      );

      if (data?.status && data?.medicaltag) {
        this.setState({
          medical_tag: true,
          valuableTypeToIdMap: {
            CPAP: 1,
          
            Nebulizer: 3,
            "Diabetes Supplies": 4,
            "Portable Oxygen Concentrator": 5,
           
            "Respiratory Machines/Accessories": 7,
            "Medical Equipment": 8,
            "Portable EKG/ECG Machine": 9,
            "Portable Defibrillators Machine": 10,
            "Insulin Pumps": 11,
           
            "Respiratory Rate Monitor": 12,
            "Portable I.V Pump": 13,
            "TENS Machine": 14,
          
            "Portable Chemotherapy Pumps": 15,
            "Blood Pressure Monitors": 16,
            "Cooling Caps" : 17
          
          },
          valuable_type: "CPAP",
          imageUrlvector: MEDICAL_VALUABLE_IMAGE?.get("CPAP"),
        });
      }
    } catch (error) {
      //unexpected error ocured
    }
  }
  async fetchBagdetails() {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/bag/getBagDetails/${this.state?.tag_number}`
      );
      //console.log(data);
      if (data?.digital_product) {
        this.setState({
          digitalqrdetails: {
            digital_product: data.digital_product,
            no_of_days: data?.no_of_days || 180,
          },
        });
      }
    } catch (er) {
      //console.log(er);
    }
  }

  componentDidMount() {
    this.checkFormedicaltag();
    // console.log(usertoken);
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    window.addEventListener("load", this.handlePageLoad);
    setTimeout(this.handlePageLoad, 3000);

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);

    this.fetchBagdetails();
    this.getLocation();
    this.checkLocation()
  }
  checkLocation = ()=>{
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
      
        if (permissionStatus.state === 'granted') {
          this.getLocation();
          // You can trigger a function here to re-fetch location data
        }
        else{
          this.setState({
            showLocationErrorModal: true,
          });
        }
      };
    });
  }
    componentWillUnmount() {
    window.removeEventListener("load", this.handlePageLoad);
  }

  handleOk = () => {
    this.setState({
      showLocationErrorModal: false,
      modalShown: true,
      retryLocation: false, // Reset retryLocation on OK click
    });
  };

  getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState(
            {
              locationData: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              showLocationErrorModal: false,
              modalShown: false,
              retryLocation: false,
            },
          
          );
        },
        (error) => {
          console.error("Error getting location:", error);
          // console.log(this.state.modalShown, "modal");
          this.setState({
            showLocationErrorModal: true,
            modalShown: true,
          });
      
        }
      );
    } else {
      this.setState({
        showLocationErrorModal: true,
        modalShown: true,
      });
      console.error("Geolocation is not supported by this browser.");
    }
  };

  submitHandler = (e) => {
    if (e) e.preventDefault();

    

    const {
      tag_status,
      valuable_type,
      imageUrl,
      description,
      date_of_purchase,
      bag_color,
      bag_brand,
      valuable_id,
      bag_imagelocation,
      imageUrlvector,
      journey_startdate,
      journey_enddate,
      modalShown,
      retryLocation,
    } = this.state;

 

    const { latitude, longitude } = this.state.locationData;

 


      this.submitForm();
    
  };

  submitForm = () => {
    const {
      tag_status,
      valuable_type,
      imageUrl,
      description,
      date_of_purchase,
      bag_color,
      bag_brand,
      valuable_id,
      bag_imagelocation,
      imageUrlvector,
      journey_startdate,
      journey_enddate,
      display_name,
      warranty_value,
      warranty_time_period,
      current_lock,
    } = this.state;

    const { latitude, longitude } = this.state.locationData;

    const data = {
      tag_number: this.props.match.params.tag_number,
      tag_status,
      valuable_type,
      imageUrl,
      description,
      date_of_purchase,
      bag_color,
      bag_brand,
      valuable_id,
      latitude: latitude || null,
      longitude: longitude || null,
      bag_imagelocation: bag_imagelocation || null,
      imageUrlvector,
      journey_startdate,
      journey_enddate,
      display_name,
      current_lock,
      warranty_value,
      warranty_time_period,
      timeZone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
    };

    let activationurl = this.state.medical_tag
      ? `${baseURL}/api/bag/medicalbagactivation`
      : `${baseURL}/api/bag/bagactivation`;

    axios
      .post(activationurl, data, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        if (response.data?.status) {
          if (this.state?.selectedImage !== null) {
            const fd = new FormData();
            fd.append("image", this.state.selectedImage);
            fd.append("tag_number", this.props.match.params.tag_number);
            this.setState({ showLoader: true });
            axios
              .post(`${baseURL}/api/bag/bagimageupload`, fd)
              .then((res) => {
                // console.log(res);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Bag Activated Successfully",
                  showLoader: false,
                });
                setTimeout(() => {
                  if (APP_ENV === "local") {
                    window.location.href = `${gateway_frontend}/#/dashboard/`;
                  } else {
                    window.location.href = `/dashboard/${localStorage.getItem(
                      "uid"
                    )}`;
                  }
                }, 1000);
              })
              .catch((error) => {
                console.error(error.message);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg:
                    error.response.data.error || "Image upload failed",
                });
                setTimeout(() => {
                  if (APP_ENV === "local") {
                    window.location.href = `${gateway_frontend}/#/dashboard/`;
                  } else {
                    window.location.href = `/dashboard/${localStorage.getItem(
                      "uid"
                    )}`;
                  }
                }, 1000);
              });
          } else {
            this.setState({
              snackbaropen: true,
              snackbarmsg:
                response?.data?.message || "Bag Activated Successfully",
              showLoader: false,
            });
            setTimeout(() => {
              if (APP_ENV === "local") {
                window.location.href = `${gateway_frontend}/#/dashboard/`;
              } else {
                window.location.href = `/dashboard/${localStorage.getItem(
                  "uid"
                )}`;
              }
            }, 2000);
          }
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: response?.data?.message || "Bag Activation Failed",
            showLoader: false,
          });
          setTimeout(() => {
            window.location.href = `/dashboard/${localStorage.getItem("uid")}`;
          }, 2000);
        }
      })
      .catch((error) => {
        // console.log("Error:", error.response?.data?.message);
        this.setState({
          snackbaropen: true,
          snackbarmsg: error.response?.data?.message || "Bag Activation Failed",
          showLoader: false,
        });
      });
  };

  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    if (!this.state?.medical_tag) {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        imageUrlvector: `https://storage.googleapis.com/pettag/qr/assets/${this.state.valuableImages[selectedId]}`,
        bag_imagelocation: null,
      });
    } else {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        bag_imagelocation: null,
        imageUrlvector: MEDICAL_VALUABLE_IMAGE?.get(selectedValuableType),
      });
    }
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }

  async handleChange(event) {
    //console.log("..............................");
    //console.log(".l");
    //console.log(event.target.files[0].size);
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      //console.log(sizeinmb);

      this.setState({ showLoader: true });
      const imageDimension = await this.imageSize(event.target.files[0]);
      //console.log(imageDimension);
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(
        event.target.files[0],
        options
      );
      //console.log(compressedFile);

      const selectedImage = compressedFile;
      //console.log(selectedImage);
      this.setState({ showLoader: false });

      if (selectedImage?.type) {
        if (
          selectedImage?.type === "image/jpeg" ||
          selectedImage?.type === "image/png" ||
          selectedImage?.type === "image/gif"
        ) {
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else if (
          selectedImage?.type === "image/heic" ||
          selectedImage?.type === "image/heif"
        ) {
          const convertedImageBlob = await heic2any({
            blob: compressedFile,
            toType: "image/jpeg",
            quality: 0.1, // cuts the quality and size
          });
          //console.log(convertedImageBlob);
          let sizeinmb = convertedImageBlob?.size / (1024 * 1024);
          // console.log(sizeinmb);
          if (sizeinmb > 5) {
          }
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
      } else {
        this.setState({
          message: "Only Png and Jpeg Images Are Acceptable",
          uploaderror: true,
          imagemodal: true,
          imagecroploading: false,
        });
      }

      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    }
  }

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {

    if(e?.target?.name === "current_lock"){
         // Allow only digits, but restrict the first digit from being '0'
         let inputValue = e.target.value;
                              
         // Replace non-numeric characters
         inputValue = inputValue.replace(/[^0-9]/g, '');
         
         // Set the cleaned value back to the input
         this.setState({ [e.target.name]:  inputValue });
    }
    else{

      this.setState({ [e.target.name]: e.target.value });

    }

     
    
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  deleteimage = (e) => {
    // console.log(e, "eeee");
    // console.log(this.state.imageUrl, "imageurk");
    // console.log(this.state, "state");
    // this.setState({
    //   snackbaropen: true,
    //   snackbarmsg: "Feature coming soon....!",
    // });
    if (this.state.imageUrl) {
      const formattedValuableType = this.state.valuable_type.replace(
        /([a-z])([A-Z])/g,
        "$1 $2"
      );
      const selectedId = this.state.valuableTypeToIdMap[formattedValuableType];
      const selectedImageFilename = this.state.valuableImages[selectedId];
      // const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
      this.setState({
        imageUrl: null,
        imageUrlvectors: `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`,
        selectedImage: null,
      });
    }
  };
  // submitHandler = (e) => {
  //   if (e) e.preventDefault();
  //   const {
  //     tag_status,
  //     valuable_type,
  //     imageUrl,
  //     description,
  //     date_of_purchase,
  //     bag_color,
  //     bag_brand,
  //     valuable_id,
  //     bag_imagelocation,
  //     imageUrlvector,
  //     vectorurl,
  //     journey_startdate,
  //     journey_enddate,
  //   } = this.state;
  //   console.log(this.state, "vectorurl");
  //   const updatedBagImagelocation = bag_imagelocation
  //     ? bag_imagelocation
  //     : null;
  //   const { latitude, longitude } = this.state.locationData;

  //   if ((!latitude || !longitude) && !this.state.modalShown) {
  //     this.setState({ showLocationErrorModal: true, modalShown: true });
  //     return;
  //   }

  //   const data = {
  //     tag_number: this.props.match.params.tag_number,
  //     tag_status,
  //     valuable_type,
  //     imageUrl,
  //     description,
  //     date_of_purchase,
  //     bag_color,
  //     bag_brand,
  //     valuable_id,
  //     latitude,
  //     longitude,
  //     bag_imagelocation: updatedBagImagelocation,
  //     imageUrlvector,
  //     journey_startdate,
  //     journey_enddate,
  //   };

  //   let activationurl;
  //   if (this.state.medical_tag) {
  //     activationurl = `${baseURL}/api/bag/medicalbagactivation`;
  //   } else {
  //     activationurl = `${baseURL}/api/bag/bagactivation`;
  //   }

  //   axios
  //     .post(activationurl, data, {
  //       headers: { Authorization: usertoken },
  //     })
  //     .then((response) => {
  //       if (response.data?.status) {
  //         if (this.state?.selectedImage !== null) {
  //           const fd = new FormData();
  //           fd.append("image", this.state.selectedImage);
  //           fd.append("tag_number", this.props.match.params.tag_number);
  //           this.setState({
  //             showLoader: true,
  //           });
  //           axios
  //             .post(`${baseURL}/api/bag/bagimageupload`, fd)
  //             .then((res) => {
  //               console.log(res);
  //               this.setState({
  //                 snackbaropen: true,
  //                 snackbarmsg: "Bag Activated Successfully",
  //                 showLoader: false,
  //               });
  //               setTimeout(() => {
  //                 // Redirect to the dashboard after a delay
  //                 if (APP_ENV === "local") {
  //                   window.location.href = gateway_frontend + "/#/dashboard/";
  //                 } else {
  //                   window.location.href =
  //                     "/dashboard/" + localStorage.getItem("uid");
  //                 }
  //               }, 1000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
  //             })
  //             .catch((error) => {
  //               console.error(error.message);
  //               this.setState({
  //                 snackbaropen: true,
  //                 snackbarmsg:
  //                   error.response.data.error || "image upload failed",
  //               });
  //               setTimeout(() => {
  //                 // Redirect to the dashboard after a delay
  //                 if (APP_ENV === "local") {
  //                   window.location.href = gateway_frontend + "/#/dashboard/";
  //                 } else {
  //                   window.location.href =
  //                     "/dashboard/" + localStorage.getItem("uid");
  //                 }

  //                 /* window.location.href =
  //                   "/dashboard/" + localStorage.getItem("uid");*/
  //               }, 1000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
  //             });
  //         } else {
  //           this.setState({
  //             snackbaropen: true,
  //             snackbarmsg:
  //               response?.data?.message || "Bag Activated Successfully",
  //             showLoader: false,
  //           });
  //           setTimeout(() => {
  //             // Redirect to the dashboard after a delay
  //             if (APP_ENV === "local") {
  //               window.location.href = gateway_frontend + "/#/dashboard/";
  //             } else {
  //               window.location.href =
  //                 "/dashboard/" + localStorage.getItem("uid");
  //             }
  //           }, 2000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
  //         }
  //       } else {
  //         this.setState({
  //           snackbaropen: true,
  //           snackbarmsg: response?.data?.message || "Bag Activated Failed",
  //           showLoader: false,
  //         });
  //         setTimeout(() => {
  //           // Redirect to the dashboard after a delay
  //           window.location.href = "/dashboard/" + localStorage.getItem("uid");
  //           /* window.location.href =
  //             "/dashboard/" + localStorage.getItem("uid");*/
  //         }, 2000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("errrorrr");
  //       console.log(error.response?.data?.message);
  //       this.setState({
  //         snackbaropen: true,
  //         snackbarmsg: error.response?.data?.message || "Bag Activated Failed",
  //         showLoader: false,
  //       });
  //     });
  // };

  cropsetup(e) {
    // console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    // console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  async handelimagecrop() {
    try {
      this.setState({ imagecroploading: true });

      setCanvasPreview(
        this.imageref?.current,
        this.canvasref?.current,
        convertToPixelCrop(
          this.state.crop,
          this.imageref?.current?.width,
          this.imageref?.current?.height
        )
      );

      this.canvasref?.current.toBlob(
        async (blob) => {
          try {
            if (blob) {
              let fileType = blob?.type; // Get the MIME type of the Blob
              let file = new File([blob], this.state?.selectedImage?.name, {
                type: fileType || "image/jpeg",
              });

              if (file?.size) {
                let sizeinmb = file?.size / (1024 * 1024);
                if (sizeinmb > 5) {
                  this.setState({
                    message: "Upload A low Quality Image",
                    uploaderror: true,
                    imagemodal: true,
                    imagecroploading: false,
                  });
                } else {
                  this.setState({
                    selectedImage: file,
                    imageUrl: URL.createObjectURL(blob),
                    key_imagelocation: null,
                    isuploading: true,
                    imagemodal: false,
                    imagecroploading: false,
                  });
                }
              }
            } else {
              this.setState({
                uploaderror: true,
                imagemodal: true,
                imagecroploading: false,
                message:
                  "Please upload images with dimensions no larger than 3000px x 3000px for Performing Croping",
                croperror: true,
              });
            }
          } catch (er) {
            this.setState({
              errorstate: `croping error :${er}`,
              isuploading: true,
              imagecroploading: false,
              uploaderror: true,
            });
          }
        },
        "image/jpeg",
        0.1
      );
    } catch (er) {
      this.setState({
        errorstate: `croping error :${er}`,
        isuploading: true,
        imagecroploading: false,
        uploaderror: true,
      });
    }
  }
   handleLostFound() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleAllTags() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleActiveInactive() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }
   walletDialogHandleClose = () => {
    this.setState({ walletDialogOpen: false });
  };

  render() {
    const {
      imageUrl,
      imageUrlvector,
      bag_imagelocation,
      tag_number,
      date_of_purchase,
      warranty_period,
      valuable_type,
      description,
      bag_color,
      bag_brand,
      sidebar,
      subMenu,
      valuableTypeToIdMap,
      valuableImages,
      valuable_id,
      locationData,
      showLocationErrorModal,
      retryLocation,
      locationBlocked,
      uploaderror,
      message,
      imagemodal,
      crop,
      digitalqrdetails,
      journey_startdate,
      journey_enddate,
      medical_tag,
      display_name,
      current_lock,
      display_baglock,
      warranty_value,
      warranty_time,
      warranty_time_period,
      show,
      setShow5,
      tagNotFound, 
      alreadyActivated,
      snackbarOpen,
    snackbarMessage,
    snackbarType,
    walletDialogOpen
      // isLoading,
    } = this.state;
    // console.log(valuableTypeToIdMap, "map");
    const { latitude, longitude } = locationData;
    const selectedId = valuableTypeToIdMap[valuable_type];
    // console.log(selectedId, "ID");
    const selectedImageFilename = valuableImages[selectedId];
    let vectorurl = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // const imageUrl = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // console.log(bag_imagelocation, "locationiamage");
    // console.log(imageUrl, "purchase date");
    // console.log(vectorurl, "vecto");
    const today = new Date().toISOString().split("T")[0];
    // const today = () => {
    //   var dtToday = new Date();
    //   var month = dtToday.getMonth() + 1;
    //   var day = dtToday.getDate();
    //   var year = dtToday.getFullYear();

    //   if (month < 10) month = "0" + month.toString();

    //   if (day < 10) day = "0" + day.toString();

    //   var today = year + "-" + month + "-" + day;

    //   return today;
    // };

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
                  
        <Navbar />
        
        <div className="editPetDetails-main-wrapper">
          <div className="bagActivation-left-wrapper">
            <Sidebarcontent
            handleShow={this.handleShow}
              handleAllTags={this.handleAllTags}
              handleActiveInactive={this.handleActiveInactive}
              handleLostFound={this.handleLostFound} />
            <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => this.setState({ show5: true })}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={this.activatetag}
                    onChange={this.handleInputChange}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="dashboard-verifyOtp-button"
                    onClick={this.activateTag}
                  >
                    ACTIVATE
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
        PaperProps={{
          style: { borderRadius: 15,padding: '1em',position:'relative' },
        }}
        open={walletDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
                aria-label="close"
                onClick={this.walletDialogHandleClose}
                style={{
                    position: 'absolute !impotant' ,
                    top: '10px !impotant',
                    right: '10px !impotant',
                    zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
                <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  } style={{ color: "blue" }}><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  } style={{ color: "blue" }}>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions> 
      </Dialog>

            <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => this.setState({ snackbarOpen: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "580px", // Optionally adjust margin
          width: "100%",
        }}
      >
        <Alert
          severity={snackbarType}
          onClose={() => this.setState({ snackbarOpen: false })}
          sx={{
            height: "50px",
            fontSize: "1.2rem",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
          </div>
          {digitalqrdetails?.digital_product ? (
            <>
              <Digitalbagcard
                tag_number={this.state.tag_number}
                tag_information={digitalqrdetails}
              />
            </>
          ) : (
            <>
              <div className="bag-container">
                <div className="background-image">
                  <div className="bag-activation-container">
                    <p>
                      <strong>
                        {medical_tag
                          ? "Medical Equipment Activation"
                          : "Bag Activation"}
                      </strong>
                    </p>

                    <div className="bag-activation-form-container">
                      {/* <form
                        onSubmit={this.submitHandler}
                        > */}
                      <form
                        className="bag-activation-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.submitHandler(e);
                        }}
                      >
                        <div className="editPetDetails-user-img">
                          {imageUrl?.length ? (
                            <>
                              <img
                                alt="imageurl"
                                src={imageUrl}
                                className="editPetDetails-image"
                              />
                            </>
                          ) : bag_imagelocation ? (
                            <>
                              <img
                                alt="imagelocation"
                                src={bag_imagelocation}
                                className="editPetDetails-image"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                alt="imageyrlvector"
                                src={imageUrlvector}
                                className="editPetDetails-image"
                              />
                            </>
                          )}
                          <div className="editPetDetails-edit-btn-wrap">
                            <label
                              htmlFor="upload"
                              className="editPetDetails-edit-button-image"
                              style={{ cursor: "pointer" }}
                            >
                              <MdIcons.MdEdit /> Change
                            </label>

                            <input
                              id="upload"
                              className="editPetDetails-input-user-img"
                              type="file"
                              accept="image/*"
                              ref={this.fileInputRef}
                              onChange={this.handleChange}
                            />
                            {this.state.imageUrl ? (
                              <label
                                className="editPetDetails-delete-button-image "
                                onClick={this.deleteimage}
                              >
                                <AiIcons.AiFillDelete /> Remove
                              </label>
                            ) : null}
                          </div>
                          <div
                            className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                            style={{ gap: "6px" }}
                          >
                            <img
                              alt="qr"
                              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                              style={{ width: "30px", height: "30px" }}
                            />
                            <p style={{ marginTop: "15px" }}>
                              <b>
                                {tag_number && tag_number !== "-"
                                  ? tag_number
                                  : "N/A"}
                              </b>
                            </p>
                          </div>
                        </div>

                        <div>
                          {
                            medical_tag ? <>
                               <div className="bagactivation-label-container">
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  marginTop: "-7px",
                                  marginLeft: "13px",
                                }}
                              >
                                Valuable Type
                              </InputLabel>
                              <Select
                                labelId="valuable-type-label"
                                id="valuable-type-select"
                                value={valuable_type}
                                label={"valuable type"}
                                required
                                onChange={this.handleDropdownChange}
                                variant="outlined"
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 250, // Max height for the dropdown
                                      overflowY: "auto", // Enable scrolling when height exceeds the limit
                                    },
                                  },
                                }}
                              >
                                {Object.keys(valuableTypeToIdMap).map(
                                  (type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                            </> : <>
                            <div className="bagactivation-label-container">
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  marginTop: "-7px",
                                  marginLeft: "13px",
                                }}
                              >
                                Valuable Type
                              </InputLabel>
                              <Select
                                labelId="valuable-type-label"
                                id="valuable-type-select"
                                value={valuable_type}
                                label={"valuable type"}
                                required
                                onChange={this.handleDropdownChange}
                                variant="outlined"
                              >
                                {Object.keys(valuableTypeToIdMap).map(
                                  (type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                            </>
                          }
                       

                          <Inputfield
                            label={
                              medical_tag
                                ? "Medical Equipment Description"
                                : "Bag Description"
                            }
                            fieldname="description"
                            type="text"
                            value={description}
                            onchange={this.changeHandler}
                          />
                          <div>
                            <Inputfield
                              label={"Display name"}
                              fieldname={"display_name"}
                              value={display_name}
                              onchange={this.changeHandler}
                              required={true}
                              labelprops={{ shrink: true }}
                            />
                          </div>

                          <Inputfield
                            label={
                              medical_tag
                                ? "Medical Equipment Brand"
                                : "Bag Brand"
                            }
                            type="text"
                            fieldname={"bag_brand"}
                            value={bag_brand}
                            onchange={this.changeHandler}
                          />
                          <Inputfield
                          required={!medical_tag && warranty_value && warranty_time_period ? true : false}
                            label={
                              medical_tag
                                ? "Medical Equipment Purchase Date"
                                : "Bag Purchase Date"
                            }
                            type="date"
                            fieldname={"date_of_purchase"}
                            value={date_of_purchase}
                            onchange={this.changeHandler}
                            inputprops={{
                              inputProps: {
                                max: new Date().toISOString().split('T')[0] // Set max selectable date to today
                              }
                            }}
                            labelprops={{ shrink: true }}
                          />
                          {!medical_tag && (
                            <div>
                              <div
                                className="warranty_label"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ width: "50%" }}>
                                  <Inputfield
                                  required={warranty_time_period ? true : false}
                                    label="Warranty Period"
                                    type="number"
                                    fieldname={"warranty_value"}
                                    value={warranty_value}
                                    onchange={this.handelwarranty}
                                    labelprops={{ shrink: true }}
                                    inputprops={{
                                      inputProps: {
                                        min: 1,
                                        onInput: (e) => {
                                          // Allow only digits, but restrict the first digit from being '0'
                                          let inputValue = e.target.value;
                                    
                                          // Replace non-numeric characters
                                          inputValue = inputValue.replace(/[^0-9]/g, '');
                                    
                                          // If the first digit is '0', remove it
                                          if (inputValue.length === 1 && inputValue === '0') {
                                            inputValue = '';
                                          }
                                    
                                          // Set the cleaned value back to the input
                                          e.target.value = inputValue;
                                        }
                                      },
                                    }}
                                  ></Inputfield>
                                </div>
                                <div
                                  style={{
                                    width: "50%",
                                    marginRight: "1rem",
                                    marginLeft: ".5rem",
                                  }}
                                >
                                  <FormControl fullWidth>
                                  <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  marginTop: "-7px",
                                  marginLeft: "13px",
                                }}
                              >
                                Duration
                              </InputLabel>
                                    <Select
                                    required={warranty_value ? true : false}
                                      labelId="valuable-type-label"
                                      id="valuable-type-select"
                                      value={warranty_time_period}
                                      label={"Month"}
                                      name="warranty_time_period"
                                      
                                      onChange={this.handelwarranty}
                                      variant="outlined"
                                    >
                                      {warranty_time?.map((e) => (
                                        <MenuItem key={e} value={e}>
                                          {e}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          )}
                          {!medical_tag && (
                            <Inputfield
                              label={"Bag Lock"}
                              type={display_baglock ? "text" : "password"}
                              fieldname={"current_lock"}
                              value={current_lock}
                              onchange={this.changeHandler}
                              inputprops={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        this.setState({
                                          display_baglock: !display_baglock,
                                        });
                                      }}
                                      aria-label="toggle password visibility"
                                      edge="end"
                                    >
                                      {display_baglock ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                inputProps: {
                                  style: { boxShadow: "none", height: "2em" },
                                  maxLength: 4,
                                  minLength: 3, // Set the minimum length here 
                                 
                                },
                              }}
                            />
                            

                          )}


                          <div className="bagactivation-btn-grp">
                            <Button
                              variant="contained"
                              type="submit"
                              style={{ width: "100%" }}
                              className="submit-login bg-warning text-white mt-2"
                            >
                              SUBMIT
                            </Button>

                            <Button
                              variant="contained"
                              type="button"
                              style={{ width: "100%" }}
                              onClick={() => {
                                window.location.href =
                                  "/dashboard/" + localStorage.getItem("uid");
                              }}
                              className="submit-login bg-white mt-2"
                            >
                              CANCEL
                            </Button>
                          </div>
                        </div>
                        {this.state.showLoader && (
                          <div className="loader-container">
                            <div className="loader">
                              <img
                                src={
                                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                                }
                                alt="Loading..."
                                loading="lazy"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          </div>
                        )}
                     
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
        </div>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              X
            </IconButton>,
          ]}
        />
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button onClick={this.handelimagecrop}>Submit</Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>

        <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showLocationErrorModal}
        autoHideDuration={6000}
        onClose={() => this.setState({ showLocationErrorModal: false })} // Use Snackbar's onClose prop
       
      >
        <Alert
          style={{ backgroundColor: "#90EE90" }}
          onClose={() => {this.setState({showLocationErrorModal:false})}}
          severity="success"
          sx={{ width: "100%" }}
        >
        To enhance your experience, please enable GPS. This step is optional, and you can still proceed without enabling it.
        </Alert>
      </Snackbar>
     {this.renderQRModal()}
      </>
    );
  }
}

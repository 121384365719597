import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./bagdetails.component.css";
import "font-awesome/css/font-awesome.min.css";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import $ from "jquery";
import Navbar from "../header/header.component";
import axios from "axios";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
// import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import imageCompression from "browser-image-compression";
import { Link } from "@mui/material";
import {
  TextField,
  Select,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
// import CircularProgress from "@mui/material/CircularProgress";
import {Snackbar} from "@mui/material";
import Alert from '@mui/material/Alert';
import { APP_ENV, switchUrls } from "../../api/index.js";
import CloseIcon from "@material-ui/icons/Close";
import { QrReader } from "react-qr-reader";
import * as FcIcons from "react-icons/fc";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from "@mui/material/DialogContentText";
// import Button from '@mui/material/Button';
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview.js";
import heic2any from "heic2any";
import Digitalbagactivation from "../bagactivation/Digitalbagactivation.jsx";
import Footer from "../mainfooter/footerblack.jsx";
import MEDICAL_VALUABLE_IMAGE from "../../utils/Helpers.js";
import Inputfield from "../ui/Inputfield.jsx";
import Sidebarcontent from "../sidebar/Sidebar.jsx";
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const baseURL = switchUrls("bag");
const basegatewayURL = switchUrls("gateway");
const gatewayFrontend = switchUrls("gateway_frontend");
const bagFrontend = switchUrls("bag_frontend");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");
const uid = localStorage.getItem("uid");

export default class BagDetails extends React.Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imagesArray: "",
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/luggage.png",
      imageUrl: "",
      vectorurl: "",
      bag_imagelocation: null,
      bag_imagename: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "Luggage",
      valuable_id: null,
      valuableTypeToIdMap: {
        "Laptop Bag": 1,
        Briefcase: 2,
        "Camera Bag": 3,
        "Gym Bag": 4,
        "Trolley Bag": 5,
        Suitcase: 6,
        "Ladies Purse": 7,
        "Sports Kit Bag": 8,
        "Duffel Bag": 9,
        "Other Bags": 10,
        "School Bag": 11,
        Luggage: 12,
      },
      valuableImages: {
        1: "laptopbag.png",
        2: "briefcase.png",
        3: "camerabag.png",
        4: "GymBag.png",
        5: "trolley-bag.png",
        6: "suitcase.png",
        7: "ladiespurse.png",
        8: "sport-bag.png",
        9: "duffelbag.png",
        10: "other.png",
        11: "schoolbag.png",
        12: "luggage.png",
      },
      // imageUrl: "",
      description: "",
      date_of_purchase: "",
      file: [null],
      bag_brand: "",
      bag_color: "",
      warranty_period: "",
      snackbaropen: false,
      isuploading: false,
      snackbarmsg: "",
      sidebar: true,
      subMenu: true,
      message: "",
      showLoader: true,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      imagecroploading: false,
      errorstate: null,
      croperror: false,
      digital_product: false,
      journey_startdate: "",
      journey_enddate: "",
      medical_tag: false,
      digitaltag_information: {
        tag_number: "",
        digital_product: false,
        airLine: "",
        bag_brand1: "",

        bag_description1: "",

        pnr: "",

        orgin: "",
        destination: "",
        journey_startdate: "",
        journey_enddate: "",
        bag_imagelocation: "",
        passenge1_firstname: "",
        passenger1_lastname: "",
        bag_image1: "",
        bag_image2: "",
        display_name: "",
      },
      current_lock: "",
      display_baglock: false,
      warranty_value: 0,
      warranty_time: ["Days", "Months", "Years"],
      warranty_time_period: "Days",
      show: false,
      show5: false,
      tagNotFound: false,
      alreadyActivated: false,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
    this.handelwarranty = this.handelwarranty.bind(this);
    this.handelredirection = this.handelredirection.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAllTags = this.handleAllTags.bind(this);
    this.handleActiveInactive = this.handleActiveInactive.bind(this);
    this.handleLostFound = this.handleLostFound.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateTag = this.activateTag.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleQRResult = this.handleQRResult.bind(this);
  }
  handelredirection() {
    window.location.href = `${bagFrontend}/#/bag-lock/${this.props.match.params.tag_number}?changelock=true`;
  }
  handelwarranty(e) {
    this.setState({
      [e?.target?.name]: e?.target?.value,
    });
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }

  uploadImg() {
    this.setState({
      uploaderror: false,
      message: false,
      imagemodal: false,
      imagecroploading: false,
      croperror: false,
    });
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/bag/getBagDetails/` + this.props.match.params.tag_number
      )
      .then((response) => {
        // console.log(response, "ress");
        if (response.data?.digital_product) {
          this.setState((prevState) => ({
            digitaltag_information: {
              ...prevState.digitaltag_information,
              tag_number: response.data?.tag_number,
              digital_product: response.data?.digital_product,
              airLine: response.data?.airLine,
              bag_brand1: response.data?.bag_brand1,

              bag_description1: response.data?.bag_description1,

              pnr: response.data?.pnr,

              orgin: response.data?.orgin,
              destination: response.data?.destination,
              journey_startdate: new Date(response.data?.journey_startdate)
                .toISOString()
                .slice(0, 10),
              journey_enddate: new Date(response.data?.journey_enddate)
                .toISOString()
                .slice(0, 10),
              bag_imagelocation: response.data?.bag_imagelocation,
              passenger1_firstname: response.data?.passenger1_firstname,
              passenger1_lastname: response.data?.passenger1_lastname,
              bag_image1: response.data?.bag_image1,
              bag_image2: response.data?.bag_image2,
              no_of_bags: response.data?.no_of_bags,

              bag_brand2: response.data?.bag_brand2 || "",

              bag_description2: response.data?.bag_description2 || "",
            },
          }));
        }
        if (response.data?.medical_tag) {
          this.setState({
            valuableTypeToIdMap: {
              CPAP: 1,
             
              Nebulizer: 3,
              "Diabetes Supplies": 4,
              "Portable Oxygen Concentrator": 5,
            
              "Respiratory Machines/Accessories": 7,
              "Medical Equipment": 8,
              "Portable EKG/ECG Machine": 9,
              "Portable Defibrillators Machine": 10,
              "Insulin Pumps": 11,
             
              "Respiratory Rate Monitor": 12,
              "Portable I.V Pump": 13,
              "TENS Machine": 14,
              
              "Portable Chemotherapy Pumps": 15,
              "Blood Pressure Monitors": 16,
              "Cooling Caps" : 17
              
            },
            medical_tag: true,
          });
        }
        let formattedPurchaseDate;
        if (response?.data?.date_of_purchase) {
          const purchaseDate = new Date(response?.data?.date_of_purchase) || "";
          formattedPurchaseDate = purchaseDate?.toISOString()?.slice(0, 10);
        }

        const valuableId =
          response?.data?.valuable_id ||
          this.state.valuableTypeToIdMap[response?.data?.valuable_type];

        this.setState({
          bag_imagelocation: response.data.bag_imagelocation,
          bag_imagename: response.data.bag_imagename,
          tag_number: response.data.tag_number,
          tag_status: response.data.tag_status,
          valuable_type: response.data?.valuable_type  === "Portable Concentrator" || response.data?.valuable_type  === "Apnea Sleep Holder Mask" ||  response.data?.valuable_type === "Oxygen Unit" ? "" : response.data?.valuable_type,
          bag_color: response.data.bag_color,
          bag_brand: response.data.bag_brand,
          description: response.data.description,
          date_of_purchase: formattedPurchaseDate,
          warranty_period: response?.data?.warranty_period || "",
          imageUrlvector: response?.data?.medical_tag
            ? MEDICAL_VALUABLE_IMAGE?.get(response?.data?.valuable_type)
            : `https://storage.googleapis.com/pettag/qr/assets/${this.state.valuableImages[valuableId]}`,
          valuable_id: response?.data?.valuable_id,
          digital_product: response?.data?.digital_product,
          journey_startdate: response?.data?.journey_startdate,
          journey_enddate: response?.data?.journey_enddate,

          display_name:
            response?.data?.display_name || localStorage?.getItem("name"),
          current_lock: response?.data?.current_lock,
          warranty_month: response.data?.warranty_month,
          warranty_year: response.data?.warranty_year,
          warranty_time_period: response.data?.warranty_time_period || "",
          warranty_value: response.data?.warranty_value || "",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: error?.response?.data?.error || "failed",
          showLoader: false,
        });
      });
    window.addEventListener("load", this.handlePageLoad);
    setTimeout(this.handlePageLoad, 3000);
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }
  componentWillUnmount() {
    window.removeEventListener("load", this.handlePageLoad);
  }
  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    if (!this.state?.medical_tag) {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        imageUrlvector: `https://storage.googleapis.com/pettag/qr/assets/${this.state.valuableImages[selectedId]}`,
        isuploading: false,
      });
    } else {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        imageUrlvector: MEDICAL_VALUABLE_IMAGE?.get(selectedValuableType),
        isuploading: false,
      });
    }
  }
  // handlePageLoad = () => {
  //   this.setState({ isLoading: false });
  // };
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  async handleChange(event) {
    // console.log("..............................");
    // console.log(".l");
    // console.log(event.target.files[0].size);
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      // console.log(sizeinmb);

      this.setState({ showLoader: true });
      const imageDimension = await this.imageSize(event.target.files[0]);
      //console.log(imageDimension);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(
        event.target.files[0],
        options
      );
      //console.log(compressedFile);

      const selectedImage = compressedFile;
      // console.log(selectedImage);
      this.setState({ showLoader: false });

      if (selectedImage?.type) {
        if (
          selectedImage?.type === "image/jpeg" ||
          selectedImage?.type === "image/png" ||
          selectedImage?.type === "image/gif"
        ) {
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else if (
          selectedImage?.type === "image/heic" ||
          selectedImage?.type === "image/heif"
        ) {
          const convertedImageBlob = await heic2any({
            blob: compressedFile,
            toType: "image/jpeg",
            quality: 0.1, // cuts the quality and size
          });
          // console.log(convertedImageBlob);
          let sizeinmb = convertedImageBlob?.size / (1024 * 1024);
          // console.log(sizeinmb);
          if (sizeinmb > 5) {
          }
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
      } else {
        this.setState({
          message: "Only Png and Jpeg Images Are Acceptable",
          uploaderror: true,
          imagemodal: true,
          imagecroploading: false,
        });
      }

      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    }
  }
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose() {
    this.setState({ show: false });
  }

  handleInputChange(e) {
    const inputValue = e.target.value
      .replace(/\s/g, "")
      .toUpperCase()
      .slice(0, 10);
    this.setState({ tag_number: inputValue });
  }
   
   handleNotFound = () => {
    this.setState({ tagNotFound: false });
  };

  handleActivated = () => {
    this.setState({ alreadyActivated: false });
  };
  activateTag() {
  const { tag_number } = this.state;
  const state = {
    tag_number: tag_number.toUpperCase(),
  };
  this.setState({ showLoader: true });
  const loaderTimer = setTimeout(() => {
    this.setState({ showLoader: false });
  }, 5000);

  axios
    .post(`${basegatewayURL}/api/checkTagNumberWithoutHeader`, state, {
      headers: {
        Authorization: this.props.token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const productcategory = res.data.tag_data.product_category;
      sessionStorage.removeItem("tag_number");

      // Check if product category starts with "Dolphin" or "Smart"
      if (
        productcategory.startsWith("Dolphin") || 
        productcategory.startsWith("Smart")
      ) {
        this.setState({ walletDialogOpen: true });
        return;
      }

      const routes = {
        "QR PET": `/pet-activation/${tag_number}`,
        "QR Key": `/key-activation/${tag_number}`,
        "QR bag": `/bag-activation/${tag_number}`,
        "QR Bag": `/bag-activation/${tag_number}`,
        "E-Bag": `/bag-activation/${tag_number}`,
        "QR Medical": `/bag-activation/${tag_number}`,
        "QR Passport": `/passport-activation/${tag_number}`,
        "E-Passport": `/passport-activation/${tag_number}`,
        "QR Gadget": `/earphone-activation/${tag_number}`,
        "Generic": `/generic-activation/${tag_number}`,
        "QR Wallet": `/wallet-activation/${tag_number}`,
        "QR Human": `/human-activation/${tag_number}`,
        "QR HUMAN": `/human-activation/${tag_number}`,
        "QR Vehicle": `/#/vehicle-activation/${tag_number}`,
      };

      if (routes[productcategory]) {
        window.location.href = routes[productcategory];
      }
    })
    .catch((error) => {
      clearTimeout(loaderTimer);
      this.setState({ showLoader: false });
      const responseData = error.response.data;
      // console.log(responseData, "data");
      if (responseData === "Tag not found by the specified number.") {
        // this.setState({ tagNotFound: true });
        this.setState({
            tagNotFound: true,
            snackbarOpen: true,
            snackbarMessage: "Tag not found!",
            snackbarType: "warning",
          });
      } else if (responseData === "Tag is already activated.") {
        // this.setState({ show: false, alreadyActivated: true });
        this.setState({
            alreadyActivated: true,
            snackbarOpen: true,
            snackbarMessage: "Tag is already activated.",
            snackbarType: "info",
          });
      }
    });
  }
  handleCloseQRModal() {
    this.setState({ show5: false });
  }
 walletDialogHandleClose = () => {
    this.setState({ walletDialogOpen: false });
  };
  handleQRResult(result, error) {
    if (result) {
      const activateTag = result?.text
        .split("/")
        [result?.text.split("/").length - 1].toUpperCase();
      this.setState({ activateTag, show5: false });
    }
    if (error) {
      console.error(error.message);
    }
  }

  renderQRModal() {
    return (
      <Dialog
        open={this.state.show5}
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseQRModal}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={this.handleQRResult}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  }
  deleteimage = (e) => {
    // console.log(e, "eeee");
    // console.log(this.state.imageUrl, "imageurk");
    // console.log(this.state, "state");
    // this.setState({
    //   snackbaropen: true,
    //   snackbarmsg: "Feature coming soon....!",
    // });
    if (this.state.imageUrl) {
      const formattedValuableType = this.state.valuable_type.replace(
        /([a-z])([A-Z])/g,
        "$1 $2"
      );
      const selectedId = this.state.valuableTypeToIdMap[formattedValuableType];
      const selectedImageFilename = this.state.valuableImages[selectedId];
      // const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
      this.setState({
        imageUrlvector: `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`,
        imageUrl: null,
        selectedImage: null,
      });
    }
    axios
      .post(`${baseURL}/api/bag/deletebagimage`, this.state, { headers: { Authorization: usertoken } })
      .then((response) => {
        // console.log(response);
        if (response.data === "Bag Image removed") {
          this.setState({
            imageUrl: "",
            bag_imagelocation: null,
            bag_imagename: null,
          });
        } else if (response.data === "wrong data") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
  };

  // submitHandler = (e) => {
  //   e.preventDefault();
  //   console.log(this.state);
  //   const {
  //     tag_status,
  //     valuable_type,
  //     imageUrl,
  //     description,
  //     date_of_purchase,
  //     bag_color,
  //     bag_brand,
  //     valuable_id,
  //     bag_imagelocation,
  //   } = this.state;
  //   console.log(imageUrl, "imageurl");
  //   console.log(bag_imagelocation, "location");
  //   const data = {
  //     tag_number: this.props.match.params.tag_number,
  //     tag_status,
  //     valuable_type,
  //     imageUrl,
  //     description,
  //     date_of_purchase,
  //     bag_color,
  //     bag_brand,
  //     valuable_id,
  //   };
  //   axios
  //     .post(
  //       `${baseURL}/api/bag/editBagDetails/` +
  //         this.props.match.params.tag_number,
  //       data,
  //       { headers: { Authorization: usertoken } }
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data === "Bag Details Updated Successfully") {
  //         window.location.href = "/dashboard/" + localStorage.getItem("uid");
  //         const fd = new FormData();
  //         console.log(this.state.selectedImage, "selected Image");
  //         console.log(this.state.imageUrlvector, "valuable");
  //         // if (this.state.imageUrlvector) {
  //         //   const selectedImageFilename = this.state.imageUrlvector;
  //         //   const imageUrl = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
  //         //   console.log(imageUrl, "url");
  //         //   fd.append("image", "");
  //         // } else {
  //         // }
  //         fd.append("image", this.state.selectedImage);
  //         fd.append("tag_number", this.props.match.params.tag_number);
  //         axios
  //           .post(`${baseURL}/api/bag/bagimageupload`, fd)
  //           .then((res) => {
  //             console.log(res);
  //             this.setState({
  //               snackbaropen: true,
  //               snackbarmsg: "Bag Details Updated Successfully",
  //             });
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       } else if (response.data === "Bag Details Update Failed") {
  //         this.setState({ snackbaropen: true, snackbarmsg: response.data });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       this.setState({
  //         snackbaropen: true,
  //         snackbarmsg: "Bag Details Update Failed",
  //       });
  //     });
  // };
  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state, "");
    const {
      tag_status,
      valuable_type,
      imageUrl,
      description,
      date_of_purchase,
      bag_color,
      bag_brand,
      valuable_id,
      bag_imagelocation,
      vectorurl,
      imageUrlvector,
      display_name,
      current_lock,
      warranty_month,
      warranty_year,
      warranty_time_period,
      warranty_value,
    } = this.state;

    const updatedBagImagelocation = bag_imagelocation
      ? bag_imagelocation
      : null;

    const data = {
      tag_number: this.props.match.params.tag_number,
      tag_status,
      valuable_type,
      imageUrl,
      description,
      date_of_purchase,
      bag_color,
      bag_brand,
      valuable_id,
      bag_imagelocation: updatedBagImagelocation,
      display_name,
      current_lock,
      warranty_month,
      warranty_year,
      timeZone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
      warranty_time_period,
      warranty_value,
    };

    axios
      .post(
        `${baseURL}/api/bag/editBagDetails/` +
          this.props.match.params.tag_number,
        data,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        const fd = new FormData();

        if (this.state?.selectedImage !== null) {
          fd.append("image", this.state.selectedImage);
          fd.append("tag_number", this.props.match.params.tag_number);
          this.setState({
            showLoader: true,
          });
          axios
            .post(`${baseURL}/api/bag/bagimageupload`, fd)
            .then((res) => {
              // console.log(res);
              this.setState({
                showLoader: false,
              });
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Bag Details Updated Successfully",
              });
              if (APP_ENV === "local") {
                window.location.href = gatewayFrontend;
              } else {
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }
            })
            .catch((error) => {
              // console.log(error);
              this.setState({
                showLoader: false,
                errorstate: `API Error Is${error}`,
              });
              this.setState({
                snackbaropen: true,
                snackbarmsg: "image upload failed",
              });
              /* window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");*/
            });
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Bag Details Updated Successfully",
          });
          if (APP_ENV === "local") {
            window.location.href = gatewayFrontend;
          } else {
            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Bag Details Update Failed",
        });
      });
  };
  cropsetup(e) {
    // console.log("dddddddddddd");
    // console.log(e.currentTarget);
    const { width, height } = e.currentTarget;
    // console.log(width, height);
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    // console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  async handelimagecrop() {
    try {
      this.setState({ imagecroploading: true });
      // console.log(this.imageref?.current.complete);

      setCanvasPreview(
        this.imageref?.current,
        this.canvasref?.current,
        convertToPixelCrop(
          this.state.crop,
          this.imageref?.current?.width,
          this.imageref?.current?.height
        )
      );

      this.canvasref?.current.toBlob(
        async (blob) => {
          try {
            if (blob) {
              let fileType = blob?.type; // Get the MIME type of the Blob
              let file = new File([blob], this.state?.selectedImage?.name, {
                type: fileType || "image/jpeg",
              });

              if (file?.size) {
                let sizeinmb = file?.size / (1024 * 1024);
                if (sizeinmb > 5) {
                  this.setState({
                    message: "Upload A low Quality Image",
                    uploaderror: true,
                    imagemodal: true,
                    imagecroploading: false,
                  });
                } else {
                  this.setState({
                    selectedImage: file,
                    imageUrl: URL.createObjectURL(blob),
                    key_imagelocation: null,
                    isuploading: true,
                    imagemodal: false,
                    imagecroploading: false,
                  });
                }
              }
            } else {
              this.setState({
                uploaderror: true,
                imagemodal: true,
                imagecroploading: false,
                message:
                  "Please upload images with dimensions no larger than 3000px x 3000px for Performing Croping",
                croperror: true,
              });
            }
          } catch (er) {
            console.log(er);

            this.setState({
              errorstate: `croping error :${er}`,
              isuploading: true,
              imagecroploading: false,
              uploaderror: true,
            });
          }
        },
        "image/jpeg",
        0.1
      );
    } catch (er) {
      console.log(er);

      this.setState({
        errorstate: `croping error :${er}`,
        isuploading: true,
        imagecroploading: false,
        uploaderror: true,
      });
    }
  }
   handleLostFound() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleAllTags() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleActiveInactive() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }
  render() {
    const {
      bag_imagelocation,
      imageUrl,
      imageUrlvector,
      bag_imagename,
      valuable_type,
      tag_number,
      date_of_purchase,
      warranty_period,
      description,
      bag_brand,
      bag_color,
      // vectorurl,
      sidebar,
      subMenu,
      valuableTypeToIdMap,
      valuableImages,
      valuable_id,
      isuploading,
      uploaderror,
      message,
      imagemodal,
      crop,
      digital_product,
      journey_startdate,
      journey_enddate,
      medical_tag,
      display_name,
      current_lock,
      display_baglock,
      warranty_month,
      warranty_limit,
      warranty_year,
      warranty_value,
      warranty_time,
      warranty_time_period,
       show,
      setShow5,
      tagNotFound, 
      alreadyActivated,
      snackbarOpen,
    snackbarMessage,
    snackbarType,
    walletDialogOpen
      // isLoading,
    } = this.state;
    // console.log(valuable_type, "map");
    // const formattedValuableTypeWithoutSpaces = valuable_type.replace(
    //   /\s+/g,
    //   ""
    // );
    // console.log(formattedValuableTypeWithoutSpaces, "mappppps");
    // const selectedIdWithoutSpaces =
    //   valuableTypeToIdMap[formattedValuableTypeWithoutSpaces];
    // console.log(selectedIdWithoutSpaces, "withoutsoaces");
    // const selectedImageFilenameWithoutSpaces =
    //   valuableImages[selectedIdWithoutSpaces];
    // console.log(selectedImageFilenameWithoutSpaces, "withoutspa");
    // const imageUrlWithoutSpaces = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilenameWithoutSpaces}`;

    // const selectedIdWithSpaces = valuableTypeToIdMap[valuable_type];
    // const selectedImageFilenameWithSpaces =
    //   valuableImages[selectedIdWithSpaces];
    // const imageUrlWithSpaces = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilenameWithSpaces}`;
    // const imageUrl = valuable_type.includes(" ")
    //   ? imageUrlWithSpaces
    // : imageUrlWithoutSpaces;

    const formattedValuableType = valuable_type.replace(
      /([a-z])([A-Z])/g,
      "$1 $2"
    );
    // console.log(formattedValuableType, "valaube");
    const selectedId = valuableTypeToIdMap[formattedValuableType];
    const selectedImageFilename = valuableImages[selectedId];
    let vectorurl = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // const imageUrl = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // console.log(bag_imagelocation, "locationiamage");
    // console.log(imageUrl, "purchase date");
    // this.setState({ imageUrlvector: imageUrlvectors });
    // console.log(vectorurl, "vecto");
    // console.log(vectorurl, "imagevector");
    const displayImageUrl = bag_imagelocation ? bag_imagelocation : imageUrl;

    // console.log(displayImageUrl, "displyae");
    const today = () => {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var today = year + "-" + month + "-" + day;
      return today;
    };

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />
        <div className="editPetDetails-main-wrapper">
          <Sidebarcontent
            showLock={digital_product || medical_tag ? false : true}
             handleShow={this.handleShow}
              handleAllTags={this.handleAllTags}
              handleActiveInactive={this.handleActiveInactive}
              handleLostFound={this.handleLostFound}
          />
          <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => this.setState({ show5: true })}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={this.activatetag}
                    onChange={this.handleInputChange}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="dashboard-verifyOtp-button"
                    onClick={this.activateTag}
                  >
                    ACTIVATE
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
             <Dialog
        PaperProps={{
          style: { borderRadius: 15,padding: '1em',position:'relative' },
        }}
        open={walletDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
                aria-label="close"
                onClick={this.walletDialogHandleClose}
                style={{
                    position: 'absolute !impotant' ,
                    top: '10px !impotant',
                    right: '10px !impotant',
                    zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
                <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  } style={{ color: "blue" }}><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  } style={{ color: "blue" }}>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions> 
      </Dialog>
             <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => this.setState({ snackbarOpen: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "580px", // Optionally adjust margin
          width: "100%",
        }}
      >
        <Alert
          severity={snackbarType}
          onClose={() => this.setState({ snackbarOpen: false })}
          sx={{
            height: "50px",
            fontSize: "1.2rem",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
          {digital_product ? (
            <Digitalbagactivation
              tag_number={tag_number}
              tag_information={this.state.digitaltag_information}
              type={"details"}
            />
          ) : (
            <>
              <div class="bag-container">
                <div className="background-image">
                  <div className="bag-activation-container">
                    <p>
                      <strong>
                        {medical_tag
                          ? "Medical Equipment Details"
                          : "Bag Details"}
                      </strong>
                    </p>

                    <div className="bag-activation-form-container">
                      <form
                        onSubmit={this.submitHandler}
                        className="bag-activation-form"
                      >
                        <div className="editPetDetails-user-img">
                          {imageUrl?.length ? (
                            <>
                              <img
                                alt="imageurl"
                                src={imageUrl}
                                className="editPetDetails-image"
                              />
                            </>
                          ) : bag_imagelocation ? (
                            <>
                              <img
                                alt="imagelocation"
                                src={bag_imagelocation}
                                className="editPetDetails-image"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                alt="imageyrlvector"
                                src={imageUrlvector}
                                className="editPetDetails-image"
                              />
                            </>
                          )}
                          <div className="editPetDetails-edit-btn-wrap">
                            <label
                              htmlFor="upload"
                              className="editPetDetails-edit-button-image"
                              style={{ cursor: "pointer" }}
                              // onClick={() => {
                              //   this.setState({
                              //     snackbaropen: true,
                              //     snackbarmsg: "Feature coming soon....!",
                              //   });
                              // }}
                            >
                              <MdIcons.MdEdit /> Change
                            </label>

                            <input
                              id="upload"
                              accept="image/*"
                              className="editPetDetails-input-user-img"
                              type="file"
                              ref={this.fileInputRef}
                              onChange={this.handleChange}
                            />
                            {imageUrl || bag_imagelocation ? (
                              <label
                                className="editPetDetails-delete-button-image "
                                onClick={this.deleteimage}
                              >
                                <AiIcons.AiFillDelete /> Remove
                              </label>
                            ) : null}
                          </div>
                          <div
                            className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                            style={{ gap: "6px" }}
                          >
                            <img
                              alt="qr"
                              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                              style={{ width: "30px", height: "30px" }}
                            />
                            <p style={{ marginTop: "15px" }}>
                              <b>
                                {tag_number && tag_number !== "-"
                                  ? tag_number
                                  : "N/A"}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div>
                          {medical_tag ? (
                            <>
                              <div className="bagactivation-label-container">
                                <FormControl fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    style={{
                                      marginTop: "-7px",
                                      marginLeft: "13px",
                                    }}
                                  >
                                    Valuable Type
                                  </InputLabel>
                                  <Select
                                    labelId="valuable-type-label"
                                    id="valuable-type-select"
                                    value={valuable_type}
                                    label={"valuable type"}
                                    required
                                    onChange={this.handleDropdownChange}
                                    variant="outlined"
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 250, // Max height for the dropdown
                                          overflowY: "auto", // Enable scrolling when height exceeds the limit
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>Select Valuable Type</em>
                                    </MenuItem>
                                    {Object.keys(valuableTypeToIdMap).map(
                                      (type) => (
                                        <MenuItem key={type} value={type}>
                                          {type}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="bagactivation-label-container">
                                <FormControl fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    style={{
                                      marginTop: "-7px",
                                      marginLeft: "13px",
                                    }}
                                  >
                                    Valuable Type
                                  </InputLabel>
                                  <Select
                                    labelId="valuable-type-label"
                                    id="valuable-type-select"
                                    value={valuable_type}
                                    label={"valuable type"}
                                    required
                                    onChange={this.handleDropdownChange}
                                    variant="outlined"
                                  >
                                    <MenuItem value="">
                                      <em>Select Valuable Type</em>
                                    </MenuItem>
                                    {Object.keys(valuableTypeToIdMap).map(
                                      (type) => (
                                        <MenuItem key={type} value={type}>
                                          {type}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </>
                          )}

                          <Inputfield
                            label={
                              medical_tag
                                ? "Medical Equipment Description"
                                : "Bag Description"
                            }
                            fieldname="description"
                            type="text"
                            value={description}
                            onchange={this.changeHandler}
                          />

                          <Inputfield
                            label={"Display name"}
                            fieldname={"display_name"}
                            value={display_name}
                            onchange={this.changeHandler}
                            required={true}
                            labelprops={{ shrink: true }}
                          />
                          <Inputfield
                            label={
                              medical_tag
                                ? "Medical Equipment OEM"
                                : "Bag Brand"
                            }
                            type="text"
                            fieldname={"bag_brand"}
                            value={bag_brand}
                            onchange={this.changeHandler}
                          />
                          <Inputfield
                            required={!medical_tag && warranty_value && warranty_time_period ? true : false}
                            label={
                              medical_tag
                                ? "Medical Equipment Purchase Date"
                                : "Bag Purchase Date"
                            }
                            type="date"
                            fieldname={"date_of_purchase"}
                            value={date_of_purchase}
                            onchange={this.changeHandler}
                            labelprops={{ shrink: true }}
                            inputprops={{
                              inputProps: {
                                max: new Date().toISOString().split('T')[0] // Set max selectable date to today
                              }
                            }}
                          />
                          {!medical_tag && (
                            <div>
                              <div
                                className="warranty_label"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ width: "50%" }}>
                                  <Inputfield
                                     required={warranty_time_period ? true : false}
                                    label="Warranty Period"
                                    type="number"
                                    fieldname={"warranty_value"}
                                    value={warranty_value}
                                    onchange={this.handelwarranty}
                                    labelprops={{ shrink: true }}
                                    inputprops={{
                                      inputProps: {
                                        min: 1,
                                        onInput: (e) => {
                                          // Allow only digits, but restrict the first digit from being '0'
                                          let inputValue = e.target.value;

                                          // Replace non-numeric characters
                                          inputValue = inputValue.replace(
                                            /[^0-9]/g,
                                            ""
                                          );

                                          // If the first digit is '0', remove it
                                          if (
                                            inputValue.length === 1 &&
                                            inputValue === "0"
                                          ) {
                                            inputValue = "";
                                          }

                                          // Set the cleaned value back to the input
                                          e.target.value = inputValue;
                                        },
                                      },
                                    }}
                                  ></Inputfield>
                                </div>
                                <div
                                  style={{
                                    width: "50%",
                                    marginRight: "1rem",
                                    marginLeft: ".5rem",
                                  }}
                                >
                                  <FormControl fullWidth>
                                  <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  marginTop: "-7px",
                                  marginLeft: "13px",
                                }}
                              >
                                Duration
                              </InputLabel>
                                    <Select
                                      required={warranty_value ? true : false}
                                      labelId="valuable-type-label"
                                      id="valuable-type-select"
                                      value={warranty_time_period}
                                      label={"Month"}
                                      name="warranty_time_period"
                                      onChange={this.handelwarranty}
                                      variant="outlined"
                                    >
                                      {warranty_time?.map((e) => (
                                        <MenuItem key={e} value={e}>
                                          {e}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          )}
                          
                          <div className="bagactivation-btn-grp">
                            <Button
                              variant="contained"
                              type="submit"
                              style={{ width: "100%" }}
                              className="submit-login bg-warning text-white mt-2"
                            >
                              SUBMIT
                            </Button>

                            <Button
                              variant="contained"
                              type="button"
                              style={{ width: "100%" }}
                              onClick={() => {
                                window.location.href =
                                  "/dashboard/" + localStorage.getItem("uid");
                              }}
                              className="submit-login bg-white mt-2"
                            >
                              CANCEL
                            </Button>
                          </div>
                     
                         

                        </div>

                        {this.state.showLoader && (
                          <div className="loader-container">
                            <div className="loader">
                              <img
                                src={
                                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                                }
                                alt="Loading..."
                                loading="lazy"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          </div>
                        )}
                        
                      </form>
                      <div className="lockchnage_section">
                        <div className="lockchange_dummydiv">

                        </div>
                        <div>

                        {/*
                            !medical_tag && (
                              <>

                              <div className="lock_div_bagdtls">

                              <Inputfield
                          
                          label={"Bag Lock"}
                          type={display_baglock ? "text" : "password"}
                          fieldname={"current_lock"}
                          value={current_lock}
                          labelprops={{ shrink: true }}
                          inputprops={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      display_baglock: !display_baglock,
                                    });
                                  }}
                                  aria-label="toggle password visibility"
                                  edge="end"
                                >
                                  {display_baglock ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            inputProps: {
                              style: { boxShadow: "none", height: "2em" },
                            },
                          }}
                        

                          

                        />

          <div className="change_lock_btndiv">

          <Button
                        onClick={this.handelredirection}
                        variant="contained"
                        type="button"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                      >
                          Change Lock
                      </Button>
            
          </div>
                   

                              </div>

               


                            
                          </>
                            )
                          */  
                          }

                        </div>

                

                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
        </div>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              X
            </IconButton>,
          ]}
        />
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            {this.state?.imagecroploading ? (
              <p>Loading..</p>
            ) : (
              <>
                <form>
                  <div style={{ margin: "1rem" }}>
                    {!uploaderror ? (
                      <>
                        <ReactCrop
                          keepSelection
                          crop={crop}
                          aspect={1}
                          minWidth={100}
                          onChange={(percentCrop) => {
                            this.setState({ crop: percentCrop });
                          }}
                        >
                          <img
                            ref={this.imageref}
                            alt="crop"
                            width="100%"
                            height="100%"
                            src={imageUrl}
                            onLoad={this.cropsetup}
                          ></img>
                        </ReactCrop>
                      </>
                    ) : (
                      <>
                        {this.state.errorstate ? (
                          <>
                            <p style={{ color: "red", textAlign: "center" }}>
                              failed
                            </p>
                            <p style={{ color: "red", textAlign: "center" }}>
                              {this.state.errorstate}
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "red", textAlign: "center" }}>
                              {message}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className="crop-actionsSub">
                    <Button onClick={this.handelcancel}>Cancel</Button>
                    {!uploaderror ? (
                      <Button onClick={this.handelimagecrop}>Submit</Button>
                    ) : null}
                  </div>
                </form>
              </>
            )}
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
             {this.renderQRModal()}

      </>
    );
  }
}
